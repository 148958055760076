.pwa-personal-shopper-container {
    padding: 0 18px !important;
}

.pwa-personal-shopper-banner {
    display: flex;
}

.pwa-personal-shopper-banner img {
    width: 100%;
    max-width: 100%;
}

.pwa-personal-shopper-title {
    font-size: 28px;
}

.pwa-personal-shopper-row {
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 64px;
}

.pwa-personal-shopper-col {
    font-size: 14px;
    flex: 0 0 50%;
    border: 1px solid #000;
    padding: 12px;
}

.pwa-personal-shopper-subtitle {
    font-size: 26px;
}

.pwa-underlined {
    text-decoration: underline;
}

.pwa-personal-shopper-cta {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.pwa-personal-shopper-cta-btn {
    background: #040505;
    color: #fff;
    padding: 12px 18px;
}

.pwa-personal-shopper-cta-info {
    margin-top: 14px;
}

.pwa-personal-shopper-cta-info p {
    font-size: 14px;
    text-align: center;
}

.pwa-personal-shopper li {
    line-height: 8mm;
}

@media (max-width: 767px) {
    .pwa-personal-shopper-container {
        padding: 16px 16px 0 16px !important;
    }

    .pwa-personal-shopper-col {
        flex: 0 0 100%;
    }
}
