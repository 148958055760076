.pwa-return-exchange {
    padding: 0 18px;
}

.pwa-return-exchange-title {
    font-size: 30px;
    margin: 0;
}

.pwa-return-exchange-nav-title {
    font-size: 28px;
    padding: 0 0 0 6px;
    margin: 16px 0;
}

.pwa-return-exchange-nav {
    margin-top: 20px;
    padding: 6px;
    background: #C7C9CB;
}

.pwa-return-exchange-nav-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.pwa-return-exchange-nav-col {
    flex: 0 0 16.666%;
    padding: 6px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
}

.pwa-return-exchange-nav-link {
    padding: 12px 0px 24px 0px;
    background: #343434;
    text-align: center;
}

.pwa-return-exchange-content-container {
    margin-top: 12px;
    background: #C7C9CB;
    padding: 12px 12px 54px 12px;
}

.pwa-return-exchange-content-title {
    font-size: 28px;
    margin: 42px 0 16px 0;
}

.pwa-return-exchange-content-subtitle {
    margin: 0;
}

.pwa-return-exchange-content-group {
    font-size: 14px;
    background: #E7E7EE;
    padding: 12px;
    text-align: justify;
}

.pwa-return-exchange-content-list:first-child {
    margin-top: 0 !important;
}

.pwa-return-exchange-content-list {
    margin-top: 24px;
}

.pwa-return-exchange li {
    line-height: 8mm;
}

@media (max-width: 1024px) {
    .pwa-return-exchange-nav-col {
        flex: 0 0 33.333%;
    }
}

@media (max-width: 767px) {
    .pwa-return-exchange {
        padding: 0 16px;
    }
    .pwa-return-exchange-nav-col {
        flex: 0 0 50%;
    }
}

@media (max-width: 360px) {
    .pwa-return-exchange-nav-col {
        flex: 0 0 100%;
    }
}