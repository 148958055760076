.pwa-faq-personal-shopper {
    padding: 0 18px;
}

.pwa-faq-personal-shopper-title {
    font-size: 28px;
}

.pwa-faq-personal-shopper ol {
    padding: 0 18px;
    margin: 0;
}

.pwa-faq-personal-shopper ul {
    list-style-type: disc;
}

.pwa-faq-personal-shopper-number-list {
    line-height: 16mm;
}

.pwa-faq-personal-shopper-order-list {
    line-height: 8mm;
}

@media (max-width: 767px) {
    .pwa-faq-personal-shopper ol {
        padding: 8px 16px !important;
        margin: 0;
    }
}