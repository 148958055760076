:root {
    --images-gap: 6px;
}

.main-app:has(.zalora-benchmark) + footer {
    margin-top: 0;
}

.main-app:has(.zalora-benchmark) {
    max-width: 100%;
}

.cms-container:has(.zalora-benchmark) {
    background-color: #fff;
}

.zalora-benchmark {
    max-width: 1028px;
    padding: 0 16px;
    margin: 0 auto;
}

.zalora-benchmark figure[data-content-type="image"] > div img,
.zalora-benchmark figure[data-content-type="image"] > a > div img,
.benchmark-slider img {
    position: relative !important;
    border-radius: 8px;
    cursor: pointer;
}

.zalora-benchmark figure[data-content-type="image"],
.benchmark-slider .slick-slide a {
    transition: all 300ms;
}

.zalora-benchmark figure[data-content-type="image"]:hover,
.benchmark-slider .slick-slide a:hover {
    filter: brightness(.9);
}

.zalora-benchmark figure,
.zalora-benchmark .benchmark-grid-image {
    margin: 0;
}

.grid-images-wrapper .pagebuilder-column-group:has(+ .benchmark-heading) {
    margin-bottom: 0;
}

.benchmark-slider {
    position: relative;
}

.benchmark-slider img {
    width: 100%;
    height: auto;
}

.benchmark-slider .left-arrow,
.benchmark-slider .right-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
    width: 45px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
}

.benchmark-slider .left-arrow,
.benchmark-slider .left-arrow:hover  {
    left: 0;
    background: linear-gradient(90deg, rgb(193, 193, 193) 0.03%, rgba(222, 222, 222, 0) 99.97%);
    color: #000;
}

.benchmark-slider .right-arrow,
.benchmark-slider .right-arrow:hover {
    right: 0;
    background: linear-gradient(270.03deg, rgb(193, 193, 193) 0.03%, rgba(222, 222, 222, 0) 99.97%);
    color: #000;
}

.benchmark-slider .left-arrow svg, 
.benchmark-slider .right-arrow svg {
    width: 12px;
}

.benchmark-slider .left-arrow:hover svg, 
.benchmark-slider .right-arrow:hover svg {
    fill: #000;
}

.benchmark-slider .dots-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.benchmark-slider .dots-wrapper div {
    width: 6px;
    height: 6px;
    background: #a9a9a9;
    margin: 5px;
    border-radius: 100px;
    cursor: pointer;
}

.benchmark-slider .dots-wrapper div.active {
    background: #000;
}

/* desktop */
@media only screen and (min-width: 1280px) {
    .cms-container:has(.zalora-benchmark) {
        padding-bottom: 48px;
    }

    .zalora-benchmark:not(.grid-images-wrapper) [class*=benchmark],
    .grid-images-wrapper .benchmark-heading:has(+ div.pagebuilder-column-group) {
        margin-bottom: calc(48px - var(--images-gap));
    }

    div[data-content-type="row"]:has(.grid-images-wrapper >.pagebuilder-column-group) + div[data-content-type="row"]:has(.grid-images-wrapper > .benchmark-heading),
    div[data-content-type="row"]:has(.grid-images-wrapper >.pagebuilder-column-group) + div[data-content-type="row"]:has(.zalora-benchmark > .benchmark-slider) {
        padding-top: 26px;
    }
}

/* tablet */
@media only screen and (min-width: 769px) and (max-width: 1279px) {
    .cms-container:has(.zalora-benchmark) {
        padding-bottom: 32px;
    }

    .zalora-benchmark:not(.grid-images-wrapper) [class*=benchmark],
    .grid-images-wrapper .benchmark-heading:has(+ div.pagebuilder-column-group) {
        margin-bottom: calc(32px - var(--images-gap));
    }

    div[data-content-type="row"]:has(.grid-images-wrapper >.pagebuilder-column-group) + div[data-content-type="row"]:has(.grid-images-wrapper > .benchmark-heading),
    div[data-content-type="row"]:has(.grid-images-wrapper >.pagebuilder-column-group) + div[data-content-type="row"]:has(.zalora-benchmark > .benchmark-slider) {
        padding-top: 10px;
    }
}

/* mobile */
@media only screen and (max-width: 768px) {
    .cms-container:has(.zalora-benchmark) {
        padding-bottom: 24px;
    }

    .zalora-benchmark:not(.grid-images-wrapper) [class*=benchmark],
    .grid-images-wrapper .benchmark-heading:has(+ div.pagebuilder-column-group) {
        margin-bottom: calc(24px - var(--images-gap));
    }

    div[data-content-type="row"]:has(.grid-images-wrapper >.pagebuilder-column-group) + div[data-content-type="row"]:has(.grid-images-wrapper > .benchmark-heading),
    div[data-content-type="row"]:has(.grid-images-wrapper >.pagebuilder-column-group) + div[data-content-type="row"]:has(.zalora-benchmark > .benchmark-slider) {
        padding-top: 10px;
    }
    
    .zalora-benchmark figure[data-content-type="image"] > div:first-child,
    .zalora-benchmark figure[data-content-type="image"] > a > div:first-child  {
        display: none !important;
    }

    .zalora-benchmark .pagebuilder-column-group {
        column-gap: 8px;
        row-gap: 16px;
    }

    .grid-images-wrapper .pagebuilder-column-group {
        margin-bottom: 8px;
    }
    
    /* .benchmark-slider .left-arrow,
    .benchmark-slider .right-arrow,
    .benchmark-slider .dots-wrapper {
        display: none;
    } */
}

@media only screen and (min-width: 769px) {
    .zalora-benchmark figure[data-content-type="image"] > div:last-child,
    .zalora-benchmark figure[data-content-type="image"] > a > div:last-child {
        display: none !important;
    }

    .zalora-benchmark .pagebuilder-column-group {
        gap: 24px;
    }

    .grid-images-wrapper .pagebuilder-column-group {
        margin-bottom: 16px;
    }
}