/* OpenSans */
@font-face {
    font-family: "Avenir-Bold";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url("/assets/fonts/Avenir/AvenirLTStd-Book.otf") format('opentype');
}
@font-face {
    font-family: "Avenir-Roman";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url("/assets/fonts/Avenir/AvenirLTStd-Roman.otf") format('opentype');
}
@font-face {
    font-family: "Avenir-Light";
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url("/assets/fonts/Avenir/AvenirLTStd-Light.otf") format('opentype');
}