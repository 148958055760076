/* Start PWA Homepage Content Wrapper*/
.pwa-homepage-content > * + * {
    margin-top: 18px;
}
/* End PWA Homepage Content Wrapper*/

/* Start Reusable Style */
.pwa-subsection-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18px;
}

.pwa-subsection-title-container {
    display: flex;
    align-items: center;
}

.pwa-subsection-title {
    font-size: 15px;
}

.pwa-subsection-subtitle {
    margin-left: 32px;
    font-size: 13px;
}

.pwa-subsection-more-text {
    font-size: 15px;
}

.pwa-subsection-more {
    display: flex;
    align-items: center;
}

.pwa-subsection-more:hover > .pwa-subsection-more-text {
    text-decoration: underline;
}

.pwa-subsection-more > img {
    width: 22px;
    height: auto;
    margin-left: 12px;
}
/* End Reusable Style */

/* Start Main Banner Section*/
.pwa-main-banner-section {
    padding: 0 18px;
}
/* End Main Banner Section*/

/* Start Sub Banner Section*/
.pwa-sub-banner-section {
    padding: 0 18px;
}
.pwa-sub-banner-section img{
    width: 100%;
}
/* End Sub Banner Section*/

/* Start Mini Banner Section*/
.pwa-mini-banner-section {
    padding: 0 9px;
    width: 100%;
}

.pwa-mini-banner {
    display: flex;
    align-items: center;
    width: 100%;
}

.pwa-mini-banner-image {
    flex: 0 0 33.333333%;
    padding: 0 9px;
}

.pwa-mini-banner-image img {
    display: block;
    width: 100%;
    height: auto;
}
/* End Mini Banner Section*/

/* Start Flash Sale Section*/
.pwa-flash-sale-section {
    padding: 0 18px;
    width: 100%;
}

.pwa-flash-sale {
    margin-left: 32px;
    display: flex;
    align-items: center;
}

.pwa-flash-sale > * + *{
    margin-left: 6px;
}

.pwa-flash-sale-days, .pwa-flash-sale-hours, .pwa-flash-sale-minutes, .pwa-flash-sale-seconds {
    font-size: 13px;
    color: #fff;
    width: 20px;
    height: 20px;
    background: #FF6A00;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pwa-flash-sale-hundred-hours {
    width: 28px !important;
}

.pwa-flash-sale-end {
    display: none;
}
/* End Flash Sale Section*/

/* Start Promo Brand Section*/
.pwa-promo-brand-section {
    padding: 0 18px;
    width: 100%;
}

.pwa-promo-brand {
    background: #fff;
    padding: 18px 0;
}

.pwa-promo-brand-banner-container {
    margin-top: 18px;
    display: flex;
    align-items: flex-start;
    padding: 0 9px;
}

.pwa-promo-brand-banner-list {
    flex: 0 0 33.3333%;
    padding: 0 9px;
}

.pwa-promo-brand-banner {
    display: block;
    width: 100%;
    height: auto;
}

.pwa-promo-brand-desc-container {
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.pwa-promo-brand-mini-icon {
    flex: 0 0 auto;
    padding: 0 32px;
}

.pwa-promo-brand-mini-icon > img {
    width: 62px;
    height: auto;
}

.pwa-promo-brand-desc {
    flex: 1 1 100%;
}

.pwa-promo-brand-desc-title {
    font-size: 8px;
}

.pwa-promo-brand-desc-content {
    margin-top: 8px;
    font-size: 14px;
}

.pwa-promo-brand-desc-time {
    font-size: 10px;
}

/* End Flash Sale Section*/

/* Start Selected Brand Section*/
.pwa-selected-brand-section {
    padding: 0 18px;
    width: 100%;
}

.pwa-selected-brand {
    background: #fff;
    padding: 18px 0 0 0;
}

.pwa-selected-brand-container {
    display: flex;
    align-items: center;
    margin-top: 18px;
    overflow-x: scroll;
}

.pwa-selected-brand-left {
    flex: 0 0 33.333%;
}

.pwa-selected-brand-left > img {
    display: block;
    width: 100%;
    height: auto;
}

.pwa-selected-brand-right {
    flex: 1 0 800px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 9px;
}

.pwa-selected-brand-list {
    flex: 0 0 25%;
    padding: 9px;
    display: flex;
    flex-direction: column;
    transition: 0.1s ease-in-out;
}

.pwa-selected-brand-list:hover {
    transform: scale(1.025);
}

.pwa-selected-brand-list > * + * {
    margin-top: 12px;
}

.pwa-selected-brand-image img {
    width: 100%;
    height: auto;
}

.pwa-selected-brand-logo, .pwa-selected-brand-disc {
    display: flex;
    justify-content: center;
    font-size: 14px;
}

.pwa-selected-brand-logo img {
    height: 15px;
    width: auto;
}

/* End Selected Brand Section*/

/* Start Product List Section*/
.pwa-product-list-section {
    padding: 0 18px;
    width: 100%;
}

.pwa-product-list {
    background: #fff;
    padding: 18px 0 0 0;
}

.pwa-product-slider-container {
    margin-top: 18px;
    display: flex;
    align-items: center;
}

.pwa-product-slider-banner {
    width: 30%;
}

.pwa-product-slider-banner > img {
    display: block;
    width: 100%;
    height: auto;
}

.pwa-product-slider-content {
    width: 70%;
}

.pwa-product-slider, .pwa-product-slider-full {
    width: 100%;
}

.pwa-product-slider-full {
    padding: 0 0 18px 0;
}
/* End Product List Section*/

/* Start Firework Section*/
.pwa-firework-section {
    padding: 0 18px;
    width: 100%;
}
/* End Firework Section*/

@media (max-width: 767px) {
    .pwa-homepage-content > * + * {
        margin-top: 10px;
    }

    .pwa-subsection-header {
        padding: 0 16px;
    }

    .pwa-main-banner-section, .pwa-mini-banner-section {
        padding: 0 0px;
    }
    /* End Main Banner Section */

    /* Start Sub Banner Section*/
    .pwa-sub-banner-section {
        margin-top: 4px;
    }

    .pwa-sub-banner-section, .pwa-mini-banner-section {
        padding: 0 0px;
    }
    /* End Sub Banner Section */
    
    /* Start Flash Sale Section*/
    .pwa-flash-sale {
        margin-left: 16px;
    }
    /* End Flash Sale Section*/

    /* Start Mini Banner Section*/
    .pwa-mini-banner {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
    
    .pwa-mini-banner-image {
        flex: 0 0 100%;
        padding: 0;
    }

    .pwa-mini-banner-image + .pwa-mini-banner-image {
        margin-top: 16px;
    }
    /* End Main Banner Section*/


    /* Start Promo Brand Section*/
    .pwa-promo-brand-section {
        padding: 0px;
    }
    
    .pwa-promo-brand {
        background: #fff;
        padding: 16px 0;
    }

    .pwa-promo-brand-banner-container {
        margin-top: 16px;
        flex-direction: column;
        padding: 0 8px;
    }

    .pwa-promo-brand-banner-list {
        flex: 0 0 100%;
        padding: 0 8px;
    }
    /* End Promo Brand Section */

    /* Start Selected Brand Section*/
    .pwa-selected-brand-section {
        padding: 0px;
    }
    .pwa-selected-brand {
        padding: 16px 0 8px 0;
    }

    .pwa-selected-brand-right {
        padding: 0 8px;
    }
    
    .pwa-selected-brand-list {
        padding: 8px;
    }
    /* End Selected Brand Section*/

    /* Start Product List Section*/
    .pwa-product-list-section {
        padding: 0;
    }

    .pwa-product-list {
        padding: 16px 0;
    }

    .pwa-product-slider-full {
        padding: 0 0 16px 0;
    }
    /* End Product List Section*/

    /* Start Flash Sale Section*/
    .pwa-flash-sale-section {
        padding: 0;
    }
    /* End Flash Sale Section*/

    /* Start Firework Section*/
    .pwa-firework-section {
        padding: 0 10px;
    }
    /* End Firework Section*/
}

@media (max-width: 1023px) {
    .pwa-selected-brand-left, .pwa-product-slider-banner {
        display: none;
    }

    .pwa-product-slider-content {
        width: 100%;
    }

    .pwa-product-slider {
        max-height: unset !important;
        overflow-y: unset !important;
    }
    .slick-slide img {
        display: block;
        width: 100%;
        height: auto;
    }
}

@media (max-width: 1100px) {
    .pwa-product-slider {
        max-height: 310px;
        overflow-y: auto;
    }
}

@media (min-width: 1261px) {
    /* Hide scrollbar for Chrome, Safari and Opera || IE and Edge */
    .pwa-selected-brand-container::-webkit-scrollbar {
        display: none;
    }
    
    .pwa-selected-brand-container {
        -ms-overflow-style: none;
        scrollbar-width: none; 
    }
    /* End hide scrollbar for Chrome, Safari and Opera || IE and Edge */

    /* Hide scrollbar for IE, Edge and Firefox */
    .pwa-selected-brand-container {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* End scrollbar for IE, Edge and Firefox */
}

/* .btn-place-order-product-list {
    display: none !important;
} */