/* start of banner header image */
.pwa-image-banner {
    width: 100%;
    height: 100%;
}

@media (min-width: 1440px) {
    .pwa-image-banner { 
        padding: 0 18px;
    }
}

.display-desktop-none {
    display: none;
}
/* end of banner header image */

/* start of brand story */
.about-us-section-2 {
    padding: 0.625em 6.625em 1.25em 6.625em;
}

.about-us__title {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25em;
    font-weight: bold;
    font-family: Avenir-Roman;
    color: #1A1818;
}

.pwa-content-about-us {
    padding: 0.25em 6.75em 2.5em 6.75em;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.about-us__content_1 {
    padding-top: 1.75em;
    text-align: center;
    font-size: 1.375em;
    font-weight: bold;
    font-family: Avenir-Roman;
    color: #1A1818;
}
/* end of brand story */

/* start of brand pillars */
.about-us-section-3 {
    text-align: center;
    padding: 0.625em 6.625em 0em 6.625em;
}

.pwa-content-brand-pillars {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 2.05em 2.25em 2.05em 2.25em;
}

.image-brand-pillars {
    width: 100%;
}

.title-image-brand-pillars {
    text-align: center;
    letter-spacing: 0px;
    color: #1A1818;
    font-size: 1.125em;
    font-weight: bold;
    font-family: Avenir-Roman;
}

.desc-image-brand-pillars {
    text-align: center;
    letter-spacing: 0px;
    color: #1A1818;
    font-size: 1.125em;
    font-family: Avenir-Roman;
}

.pwa-pillars-brand {
    padding-bottom: 1.25em;
}
/* end of brand pillars */

/* start of our journey */
.about-us-section-4 {
    text-align: center;
    padding: 0em 6.625em 0em 6.625em;
}
/* end of our journey */

/* start of mobile view */
@media (max-width: 767px) {

    #about-us {
        padding: 16px 0 0 0;
    }

    .about-us-section-2 {
        padding: 0em;
    }

    .pwa-content-about-us {
        padding: 0.25em 2.75em 2.5em 2.75em;
    }

    .about-us__content_1 {
        font-size: 1.125em;
    }

    .about-us-section-3 {
        padding: 0em;
    }

    .pwa-content-brand-pillars {
        padding: 2.05em 1.05em 2.05em 1.05em;
    }

    .title-image-brand-pillars {
        font-size: 0.9375em;
    }

    .desc-image-brand-pillars {
        font-size: 0.9375em;
    }

    .about-us-section-4 {
        padding: 0em;
    }
}
/* end of mobile view */