.pwa-contact-us {
    padding: 0 18px;
}

.pwa-contact-us-title {
    font-size: 30px;
    margin: 0;
}

.pwa-contact-us-nav-title {
    font-size: 28px;
    padding: 0 0 0 6px;
    margin: 16px 0;
}

.pwa-contact-us-nav {
    margin-top: 20px;
    padding: 6px;
    background: #C7C9CB;
}

.pwa-contact-us-nav-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.pwa-contact-us-nav-col {
    flex: 0 0 16.666%;
    padding: 6px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
}

.pwa-contact-us-nav-link {
    padding: 12px 0px 24px 0px;
    background: #343434;
    text-align: center;
}

.pwa-contact-us-content-container {
    margin-top: 12px;
    background: #C7C9CB;
    padding: 12px 6px 54px 6px;
}

.pwa-contact-us-content-title {
    font-size: 28px;
    margin: 42px 0 16px 0;
    padding-left: 6px;
}

.pwa-contact-us-content-subtitle {
    margin: 0;
}

.pwa-contact-us-content-group {
    font-size: 14px;
    text-align: justify;
    display: flex;
    flex-wrap: wrap;
}

.pwa-contact-us-content-list-container {
    flex: 0 0 33.333%;
    padding: 6px;
}

.pwa-contact-us-content-list {
    background: #E7E7EE;
    height: 100%;
    padding: 12px;
}

.pwa-contact-us-content-subtitle {
    font-size: 18px;
}

.pwa-contact-us li {
    line-height: 8mm;
}

@media (max-width: 1024px) {
    .pwa-contact-us-nav-col {
        flex: 0 0 33.333%;
    }
}

@media (max-width: 767px) {
    .pwa-contact-us {
        padding: 0 16px;
    }

    .pwa-contact-us-nav-col {
        flex: 0 0 50%;
    }

    .pwa-contact-us-content-list-container {
        flex: 0 0 100%;
    }
}

@media (max-width: 360px) {
    .pwa-contact-us-nav-col {
        flex: 0 0 100%;
    }
}