.pwa-privacy-policy {
    padding: 0 18px;
}

.pwa-privacy-policy-title {
    font-size: 30px;
    margin: 0;
}

.pwa-privacy-policy-nav-title {
    font-size: 28px;
    padding: 0 0 0 6px;
    margin: 16px 0;
}

.pwa-privacy-policy-nav {
    margin-top: 20px;
    padding: 6px;
    background: #C7C9CB;
}

.pwa-privacy-policy-nav-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.pwa-privacy-policy-nav-col {
    flex: 0 0 25%;
    padding: 6px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
}

.pwa-privacy-policy-nav-link {
    padding: 12px 0px 24px 0px;
    background: #343434;
    text-align: center;
}

.pwa-privacy-policy-content {
    margin-top: 12px;
    background: #C7C9CB;
    padding: 12px 12px 54px 12px;
}

.pwa-privacy-policy-content-title {
    font-size: 16px;
}

.pwa-privacy-policy-content-title-special {
    font-size: 28px;
    margin: 42px 0 16px 0;
}

.pwa-privacy-policy-content-desc {
    font-size: 14px;
    background: #E7E7EE;
    padding: 12px;
    text-align: justify;
}

.pwa-privacy-policy li {
    line-height: 8mm;
}

@media (max-width: 767px) {
    .pwa-privacy-policy {
        padding: 0 16px;
    }
    .pwa-privacy-policy-nav-col {
        flex: 0 0 50%;
    }
}

@media (max-width: 360px) {
    .pwa-privacy-policy-nav-col {
        flex: 0 0 100%;
    }
}