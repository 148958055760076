/* Start PWA Footer Section 3 */
.pwa-footer-about-container {
    background: #E7E7EE;
}

.pwa-footer-about {
    padding: 32px 18px;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
}

.pwa-footer-about-left {
    flex: 0 0 auto;
}

.pwa-footer-about-right {
    margin-left: 24px;
    flex: 1 1 100%;
}

.pwa-footer-about-title {
    margin-top: 0;
    margin-bottom: 13px;
    font-size: 13px;
}

.pwa-footer-about-text {
    font-size: 13px;
}

.pwa-footer-about-text:nth-last-child(1) {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .pwa-footer-about {
        flex-direction: column;
        flex-wrap: wrap;
        padding: 32px 16px !important;
        align-items: center;
    }

    .pwa-footer-about-left, .pwa-footer-about-right {
        flex: 0 0 100%;
    }
    
    .pwa-footer-about-left, .pwa-footer-about-title {
        text-align: center;
    }

    .pwa-footer-about-right {
        margin-left: 0 !important;
    }
}
/* End PWA Footer Section 3  */

/* Start PWA Footer Section 2 */
.pwa-footer-menu-container {
    background: #040505;
}

.pwa-footer-menu {
    max-width: 1440px;
    padding: 32px 18px;
    margin: 0 auto;
    display: flex;
    color: #fff;
    font-size: 13px;
    justify-content: space-between;
    align-items: flex-start;
}

.pwa-menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 0 0 auto;
}

.pwa-menu-list:nth-last-child(1) {
    flex: 0 0 360px;
}

@media (max-width: 992px) {
    .pwa-menu-list:nth-last-child(1) {
        flex: 0 0 200px;
    }
    .pwa-menu-list-payment > * {
        height: auto;
        width: 38px !important;
    }
}

.pwa-menu-list-title {
    text-transform: uppercase;
    margin-bottom: 12px;
    line-height: 1.5em;
}

.pwa-menu-list-item {
    margin-bottom: 4px;
}

.pwa-menu-list-item:nth-last-child(1) {
    margin-bottom: 0;
}

.pwa-menu-list-payment {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.pwa-menu-list-payment > * {
    height: auto;
    margin: 0 8px 8px 0;
    width: 48px;
}

.pwa-menu-list-item a:hover {
    text-decoration: underline;
}

.pwa-menu-download-app {
    display: flex;
    flex-direction: column;
}

.pwa-menu-download-app img {
    width: 112px;
    height: auto;
}

.pwa-menu-download-app > img + img {
    margin-top: 12px;
}

.pwa-menu-download-app-title {
    margin-top: 28px;
    max-width: 160px;
}

@media (max-width: 767px) {
    .pwa-footer-menu {
        flex-direction: column;
        flex-wrap: wrap;
        padding: 32px 16px !Important;
        align-items: center;
    }

    .pwa-footer-menu > * + * {
        margin-top: 32px;
    }

    .pwa-menu-list {
        text-align: center !important;
        flex: 0 0 100% !important;
    }

    .pwa-menu-download-app-title {
        margin-top: 32px;
        max-width: 100%;
    }

    .pwa-menu-list-payment {
        justify-content: center;
    }

    .pwa-menu-list-payment > * {
        margin: 4px !important;
    }
}
/* End PWA Footer Section 2 */

/* Start PWA Footer Section 1 */
.pwa-footer-newsletter-container {
    background: #C7C9CB;
}

.pwa-footer-newsletter {
    max-width: 900px;
    padding: 32px 18px;
    margin: 0 auto;
    display: flex;
}

.pwa-footer-newsletter-left, .pwa-footer-newsletter-right {
    flex: 0 0 50%;
}

.pwa-footer-newsletter-left {
    padding-right: 16px;
}

.pwa-footer-newsletter-right {
    padding-left: 16px;
}

.pwa-footer-newsletter-left-title {
    font-size: 15px;
    margin: 0;
}

.pwa-footer-newsletter-left-desc {
    font-size: 15px;
    margin: 20px 0 0 0;
}

.pwa-footer-newsletter-right-title {
    font-size: 15px;
    text-transform: uppercase;
    margin: 0;
}

.pwa-footer-newsletter-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pwa-footer-newsletter-form > input[type=email] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 20px 0 0 0;
    font-size: 12px;
    padding: 0;
    border: 0;
    outline: 0;
    border-radius: 666px;
    padding: 8.5px 16px;
    width: 100%;
}

.pwa-footer-newsletter-action {
    display: flex;
    align-items: flex-start;
    margin-top: 12px;
}

.pwa-footer-newsletter-desc {
    flex: 1 1 auto;
    font-size: 13px;
    margin: 0;
}

.pwa-footer-newsletter-action > button {
    border: 0;
    outline: 0;
    background: #1A1818;
    color: #fff;
    text-transform: uppercase;
    margin-left: 12px;
    padding: 10px 28px;
    font-size: 12px;
    position: relative;
    transition: 0.2s ease-in-out;
}

.pwa-footer-newsletter-action > button:hover {
    cursor: pointer;
    background: #FF6A00;
}

.pwa-footer-newsletter-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.pwa-footer-newsletter-display-none {
    display: none;
}

.pwa-footer-newsletter-display-block {
    display: block;
}

.pwa-footer-newsletter-transparent {
    opacity: 0;
}

@media (max-width: 767px) {
    .pwa-footer-newsletter {
        flex-direction: column;
        flex-wrap: wrap;
        padding: 32px 16px !important;
    }

    .pwa-footer-newsletter-left {
        display: none;
    }

    .pwa-footer-newsletter-right {
        flex: 0 0 100%;
        padding: 0 !important;
    }
}
/* End PWA Footer Section 1 */

/* Start PWA Footer Mobile */
.pwa-footer-mobile-container {
    padding: 42px 16px;
    background: #fff;
}

.pwa-footer-mobile {
    min-height: 50vh;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.pwa-footer-mobile > * + * {
    margin-top: 20px;
}

.pwa-footer-mobile-menu {
    flex: 0 0 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
}
/* End PWA Footer Mobile */