.pwa-size-guide {
    padding: 0 18px;
}

.pwa-size-guide-title {
    font-size: 30px;
    margin: 0;
}

.pwa-size-guide-nav-title {
    font-size: 28px;
    padding: 0 0 0 6px;
    margin: 16px 0;
}

.pwa-size-guide-nav {
    margin-top: 20px;
    padding: 6px;
    background: #C7C9CB;
}

.pwa-size-guide-nav-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.pwa-size-guide-nav-col {
    flex: 0 0 16.666%;
    padding: 6px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
}

.pwa-size-guide-nav-link {
    padding: 12px 0px 24px 0px;
    background: #343434;
    text-align: center;
}

.pwa-size-guide-content-container {
    margin-top: 12px;
    background: #C7C9CB;
    padding: 12px 12px 54px 12px;
}

.pwa-size-guide-content-title {
    font-size: 28px;
    margin: 42px 0 16px 0;
}

.pwa-size-guide-content-group {
    display: flex;
    flex-direction: column;
}

.pwa-size-guide-content-group > * + * {
    margin-top: 12px;
}

.pwa-size-guide-chart-title-container {
    background: #E7E7EE;
    padding: 12px 12px 24px 12px;
}

.pwa-size-guide-chart-title {
    text-align: center;
    font-size: 18px;
    margin: 0;
}

.pwa-size-guide-chart-category-container {
    background: #E7E7EE;
    padding: 12px;
}

.pwa-size-guide-category-title {
    margin: 0;
    font-size: 18px;;
}

.pwa-size-guide-content-row {
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
    background: #E7E7EE;
}

.pwa-size-guide-content-col-3 {
    flex: 0 0 25%;
    padding: 6px;
}

.pwa-size-guide-content-col-12 {
    flex: 0 0 100%;
    padding: 6px;
}

.pwa-size-guide-subcategory-title {
    margin: 0;
    font-size: 20px;
    padding-bottom: 12px;
}

.pwa-size-guide-table table {
    width: 100%;
    border-collapse: collapse;
    padding: 0;
    margin: 0;
    table-layout: fixed;
}

.pwa-size-guide-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #000;
}

.pwa-size-guide-table-row-title {
    background: #C7C9CB;
}

@media (max-width: 1024px) {
    .pwa-size-guide-nav-col {
        flex: 0 0 33.333%;
    }

    .pwa-size-guide-content-col-3 {
        flex: 0 0 33.333%;
    }
}

@media (max-width: 767px) {
    .pwa-size-guide {
        padding: 0 16px;
    }

    .pwa-size-guide-nav-col {
        flex: 0 0 50%;
    }

    .pwa-size-guide-content-col-3 {
        flex: 0 0 50%;
    }
}

@media (max-width: 480px) {
    .pwa-size-guide-content-col-3 {
        flex: 0 0 100%;
    }
}

@media (max-width: 360px) {
    .pwa-size-guide-nav-col {
        flex: 0 0 100%;
    }
}